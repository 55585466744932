import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

class UserGuide extends React.Component {
    render = () => {
        return <GridContainer justify="center" style={{marginTop: "3rem"}}>
            <GridItem xs={12} sm={10} md={8} lg={6} xl={4}>
                <iframe src="/userguide/NACLNewUserGuide.html" title="User Guide" style={{width: "inherit", height: "70rem"}}/>
            </GridItem>
        </GridContainer>
    }
}

export default UserGuide;
