import React, {Component} from 'react';
import {Typography, Zoom} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import Portis from "@portis/web3";
import Fortmatic from "fortmatic";
import Authereum from "authereum";
import Web3Modal from "web3modal";
import Web3 from "web3";
import assist from "bnc-assist";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import {bn_id} from "../../constants";

class ConnectWalletForm extends Component {
    state = {
        assistInstance: null
    };

    componentDidMount = async () => {
        const providerOptions = {
            portis: {
                package: Portis,
                options: {
                    id: 'f3b1dfa9-feee-44c9-8e41-3deca837c2e8'
                }
            },
            fortmatic: {
                package: Fortmatic,
                options: {
                    key: "pk_test_65A6E555F998A485"
                }
            },
            authereum: {
                package: Authereum
            }
        };
        this.setState({
            web3Modal: new Web3Modal({
                network: "mainnet",
                cacheProvider: true,
                providerOptions
            })
        });
    };

    initWallet = () => {
        this.state.web3Modal.connect().then(provider => {
            let bncAssistConfig = {
                dappId: bn_id,
                web3: new Web3(provider),
                networkId: 1
            };
            this.setState({assistInstance: assist.init(bncAssistConfig)}, () => {
                this.state.assistInstance.onboard().then((state) => {
                    this.props.setPublicAddress(state.accountAddress, () => {
                        this.props.setWalletType(this.state.web3Modal.providerController.cachedProvider, () => {
                            this.state.web3Modal.clearCachedProvider();
                            this.props.createUser();
                        });
                    });
                });
            });
        });
    };

    render = () => {
        return <Zoom in={true}><GridContainer justify="center" style={{textAlign: "center", minHeight: "70rem"}}>
            <GridItem xs={8} style={{marginTop: "3rem"}}>
                <Card plain={true}>
                    <GridContainer justify="center" style={{textAlign: "center"}}>
                        <GridItem xs={12} md={12}>
                            <img src={require("assets/img/wallet.png")} alt="wallet"/>
                        </GridItem>
                        <GridItem xs={12} md={12} style={{marginTop: "2rem"}}>
                            <Typography variant="h6">Optionally, Connect A Wallet To Receive Tournament Prizes in Crypto</Typography>
                        </GridItem>
                        <GridItem xs={12} md={4} lg={2}>
                            <Card plain={true}>
                                <Button color="success"
                                        onClick={this.initWallet}><Typography
                                    variant="button">Connect Wallet</Typography></Button>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} md={4} lg={2}>
                            <Card plain={true}>
                                <Button color="warning"
                                        onClick={this.props.createUser}><Typography
                                    variant="button">Skip</Typography></Button>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </Card>
            </GridItem>
        </GridContainer></Zoom>;
    }
}

export default ConnectWalletForm;
