import React from "react";
import {withStyles, Slide, Dialog, DialogContent} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import Typography from "@material-ui/core/Typography";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CustomInput from "../CustomInput/CustomInput";
import CardFooter from "../Card/CardFooter";
import styles from "assets/jss/material-kit-react/views/loginPage.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Email} from "@material-ui/icons";
import Icon from "@material-ui/core/Icon";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

function SignInModal({...props}) {
    const {classes, openState, closeModal, handleSimple, signIn, history} = props;

    return <Dialog
        open={openState}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closeModal("signInModal")}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
    >
        <DialogContent>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card plain={true}>
                        <form onSubmit={signIn}>
                            <CardHeader plain={true} className={classes.cardHeader}>
                                <Typography variant="h4" fontWeight="fontWeightBold">Sign In</Typography>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Email Or Username..."
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "text",
                                        name: "username",
                                        endAdornment: <InputAdornment position="end">
                                            <Email className={classes.inputIconsColor}/>
                                        </InputAdornment>,
                                        onChange: handleSimple
                                    }}
                                />
                                <CustomInput
                                    labelText="Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "password",
                                        name: "password",
                                        endAdornment: <InputAdornment position="end">
                                            <Icon className={classes.inputIconsColor}>
                                                lock_outline
                                            </Icon>
                                        </InputAdornment>,
                                        onChange: handleSimple
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.cardFooter}>
                                <Button color="success" type="primary" htmltype="submit"
                                        style={{marginTop: "2rem"}}>Submit</Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
                <GridItem>
                    <Button simple color="primary" size="lg" onClick={() => history.push("/forgotPassword")}>
                        Forgot Password
                    </Button>
                </GridItem>
            </GridContainer>
        </DialogContent>
    </Dialog>;
}

export default withStyles(styles)(SignInModal);
