import Fab from "@material-ui/core/Fab";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import {initWalletProvider} from "../../utils";
import React from "react";

let onClickFunction = async () => {
    if (localStorage.getItem("walletType") === "PORTIS") {
        const provider = await initWalletProvider();
        provider._portis.showPortis();
    } else if (localStorage.getItem("walletType") === "AUTHEREUM") {
        const url = "https://authereum.com/account";
        window.open(url, '_blank', "location=yes,height=850,width=450,scrollbars=yes,status=yes");
    } else if (localStorage.getItem("walletType") === "FORTMATIC" || localStorage.getItem("walletType") === "WEB3_BROWSER") {
        const url = `https://app.zerion.io/${localStorage.getItem("publicAddress")}/overview?utm_campaign=aw&utm_medium=main_cta&utm_source=landing`;
        window.open(url, '_blank', "location=yes,height=850,width=450,scrollbars=yes,status=yes");
    } else return <div/>;
};

export const WalletFAB = () => {
    let text = "";
    if (localStorage.getItem("walletType") === "PORTIS") {
        text = "Portis Wallet";
    } else if (localStorage.getItem("walletType") === "AUTHEREUM") {
        text = "Authereum Wallet";
    } else if (localStorage.getItem("walletType") === "FORTMATIC") {
        text = "View My Assets";
    } else if (localStorage.getItem("walletType") === "WEB3_BROWSER") {
        text = "View My Assets";
    } else return <div/>;

    return <Fab color="primary" style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    }} aria-label="add" variant="extended" onClick={onClickFunction}>
        <WalletIcon/>
        {text}
    </Fab>
};
