import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import gql from "graphql-tag";
import {apolloClient} from "../../utils";
import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx";
import Button from "../../components/CustomButtons/Button";

const GET_USER = userId => gql`{
    user(id: "${userId}") {
        id
        name
        username
        avatar
        walletType
        publicAddress
        teams {
            id
            name
        }
        socialLinks {
            instagram
            twitter
            twitch
            facebook
            mixer
            youtube
        }
    }
}`;

class ProfilePage extends React.Component {
    state = {
        userId: this.props.match.params.id
    };
    componentDidMount = () => {
        apolloClient.query({
            query: GET_USER(this.state.userId)
        }).then(response => {
            this.setState({user: response.data.user})
        }).catch(error => {
            console.error({error});
            this.setState({error});
        })
    };

    render() {
        if (!this.state.user) {
            return "User does not exist";
        }
        const {classes} = this.props;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid
        );
        return (
            <div style={{minHeight: "70rem"}}>
                <Parallax small filter image={require("assets/img/paralax.jpg")}/>
                <div>
                    <div>
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={this.state.user.avatar} alt="..." className={imageClasses}/>
                                        </div>
                                        <div className={classes.name}>
                                            <h3 className={classes.title}>{this.state.user.name}</h3>
                                            <h6>{this.state.user.username}</h6>
                                        </div>
                                        {this.state.user.socialLinks.twitter ? <Button
                                            onClick={() => window.open(this.state.user.socialLinks.twitter, '_blank')}
                                            justIcon link className={classes.margin5}>
                                            <i className={"fab fa-twitter"}/>
                                        </Button> : "" }
                                        {this.state.user.socialLinks.instagram ? <Button
                                            onClick={() => window.open(this.state.user.socialLinks.instagram, '_blank')}
                                            justIcon link className={classes.margin5}>
                                            <i className={"fab fa-instagram"}/>
                                        </Button> : "" }
                                        {this.state.user.socialLinks.facebook ? <Button
                                            onClick={() => window.open(this.state.user.socialLinks.facebook, '_blank')}
                                            justIcon link className={classes.margin5}>
                                            <i className={"fab fa-facebook"}/>
                                        </Button> : "" }
                                        {this.state.user.socialLinks.twitch ? <Button
                                            onClick={() => window.open(this.state.user.socialLinks.twitch, '_blank')}
                                            justIcon link className={classes.margin5}>
                                            <i className={"fab fa-twitch"}/>
                                        </Button> : "" }
                                        {this.state.user.socialLinks.mixer ? <Button
                                            onClick={() => window.open(this.state.user.socialLinks.mixer, '_blank')}
                                            justIcon link className={classes.margin5}>
                                            <i className={"fas fa-tv"}/>
                                        </Button> : "" }
                                        {this.state.user.socialLinks.youtube ? <Button
                                            onClick={() => window.open(this.state.user.socialLinks.youtube, '_blank')}
                                            justIcon link className={classes.margin5}>
                                            <i className={"fab fa-youtube"}/>
                                        </Button> : "" }
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProfilePage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(profilePageStyle)(ProfilePage);
