import axios from "axios";
import {base, bn_id} from "../constants";
import {ApolloClient} from "apollo-client";
import {HttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import gql from "graphql-tag";
import Web3 from "web3";
import Portis from "@portis/web3";
import assist from "bnc-assist";
import Web3Modal from "web3modal";
import Fortmatic from "fortmatic";
import Authereum from "authereum";

const GET_MY_PROFILE = gql` {
    myProfile {
        id
        name
        email
        publicAddress
        avatar
    }
}`;

const signOnUser = (assistInstance, web3) => {
    return new Promise((resolve, reject) => {
        assistInstance.onboard().then(() => {
            assistInstance.getState().then(state => {
                web3.personal.sign(
                    web3.sha3("hello world"), state.accountAddress,
                    (error, result) => {
                        if (!error) {
                            console.log(result);
                            axios
                                .post(`${base}/api/user`, {
                                    accountAddress: state.accountAddress,
                                    signature: result
                                })
                                .then(response => {
                                    localStorage.setItem("username", response.data.username);
                                    localStorage.setItem(
                                        "publicAddress",
                                        response.data.publicAddress
                                    );
                                    localStorage.setItem("userId", response.data.userId);
                                    localStorage.setItem("jwtToken", response.data.accessToken);
                                    localStorage.setItem("userAvatar", response.data.userAvatar);
                                    resolve(response.data);
                                })
                                .catch(e => {
                                    console.log({e});
                                    reject(e);
                                });
                        } else {
                            console.log(error);
                            reject(error);
                        }
                    });
            });
        });
    });
};

const signOut = async () => {
    await asyncLocalStorage.removeItem("username");
    await asyncLocalStorage.removeItem("publicAddress");
    await asyncLocalStorage.removeItem("userId");
    await asyncLocalStorage.removeItem("jwtToken");
    await asyncLocalStorage.removeItem("walletType");
    await asyncLocalStorage.removeItem("organizer");
    const web3Modal = new Web3Modal({ cacheProvider: true });
    web3Modal.clearCachedProvider();
};

const isUserSignedIn = async () => {
    if (localStorage.getItem("jwtToken")) {
        try {
            await apolloClient.query({query: GET_MY_PROFILE});
            return true;
        } catch (e) {
            return false;
        }
    } else {
        return false;
    }
};

const asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    },
    removeItem: function(key) {
        return Promise.resolve().then(function () {
            return localStorage.removeItem(key);
        })
    }
};

function isEmpty(str) {
    return !str || 0 === str.length;
}

function sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

const prepUserForContract = (assistInstance, history) => {
    return new Promise((resolve, reject) => {
        assistInstance.onboard().then(() => {
            apolloClient
                .query({
                    query: GET_MY_PROFILE
                }).then(response => {
                const responseData = response.data.myProfile;
                if (
                    isEmpty(responseData.name) ||
                    isEmpty(responseData.email)
                ) {
                    if (window.location.pathname !== "/editUser") {
                        history.push("/editUser");
                    }
                } else {
                    resolve(responseData);
                }
            })
                .catch(e => {
                    console.log({e});
                    reject(e);
                });
        });
    });
};

const apolloClient = new ApolloClient({
    link: new HttpLink({
        uri: `${base}/graphql`,
        headers: {Authorization: "Bearer " + localStorage.getItem("jwtToken")}
    }),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            errorPolicy: "ignore"
        }
    }
});

const initWeb3 = async config => {
    let provider;
    if (localStorage.getItem("walletType") === "WEB3_BROWSER") {
        if (window.web3) provider = window.web3.currentProvider;
        else window.alert("Browser is not web3 enabled");
    } else if (localStorage.getItem("walletType") === "NO_WALLET" || !localStorage.getItem("walletType")) {
        return new Promise(resolve => resolve({web3: null, assistInstance: null}));
    } else {
        provider = await initWalletProvider();
    }
    const web3 = await initWeb3Instance(provider);
    let bncAssistConfig = {
        dappId: bn_id,
        networkId: 1,
        web3: web3,
        messages: {
            txPending: () => {
                return `Creating ${this.state.title}.`;
            },
            txConfirmed: () => {
                return `Created ${this.state.title} Successfully.`;
            }
        }
    };
    if (config !== undefined) {
        bncAssistConfig = Object.assign({}, bncAssistConfig, config);
    }
    const assistInstance = await assist.init(bncAssistConfig);
    if (localStorage.getItem("walletType") === "WEB3_BROWSER") await assistInstance.onboard();
    return new Promise(resolve => resolve({web3, assistInstance}));
};

const initWalletProvider = async () => {
    const providerOptions = {
        portis: {
            package: Portis,
            options: {
                id: 'f3b1dfa9-feee-44c9-8e41-3deca837c2e8'
            }
        },
        fortmatic: {
            package: Fortmatic,
            options: {
                key: "pk_test_65A6E555F998A485"
            }
        },
        authereum: {
            package: Authereum
        }
    };
    const web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: true,
        providerOptions
    });
    let provider;
    if (web3Modal.cachedProvider) {
        provider = await web3Modal.connect();
    } else {
        provider = await web3Modal.connectTo(localStorage.getItem("walletType").toLowerCase());
    }
    return new Promise(resolve => {
        resolve(provider)
    });
};

const initWeb3Instance = (provider) => {
    return new Promise(resolve => {
        resolve(new Web3(provider));
    });
};

export {apolloClient, prepUserForContract, signOnUser, sleep, initWeb3, isUserSignedIn, initWalletProvider, signOut, asyncLocalStorage};
