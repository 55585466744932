import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/Input/index";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

function BracketDetails({...props}) {
    const {handleSimple, handlePointsDistribution, maxTeams, bracketType, classes} = props;
    let cells = [];
    for (let i = 0; i < maxTeams; i++) {
        cells.push(
            <TableRow cursor="pointer" key={i}>
                <TableCell component="th" scope="row">{i + 1}</TableCell>
                <TableCell><Input
                    inputProps={{
                        name: i,
                        type: "number",
                        onChange: handlePointsDistribution,
                        placeholder: 0
                    }}
                /></TableCell>
            </TableRow>
        )
    }

    return <div>
        <GridContainer>
            <GridItem xs={12}>
                <Select
                    native
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    style={{width: "50%", marginTop: "1rem", marginBottom: "0.5rem"}}
                    value={bracketType}
                    variant="outlined"
                    inputProps={{
                        name: "bracketType",
                        id: "bracketType"
                    }}
                    onChange={handleSimple}
                >
                    <option value="" disabled>
                        Bracket Type
                    </option>
                    <option
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                        value="SINGLE_ELIMINATION"
                    >
                        Single Elimination
                    </option>
                    <option
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                        value="BATTLE_ROYALE"
                    >
                        Battle Royale
                    </option>
                    <option
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                        value="DOUBLE_ELIMINATION"
                    >
                        Double Elimination
                    </option>
                    <option
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                        value="ROUND_ROBIN"
                    >
                        Round Robin (Not Yet Available)
                    </option>
                </Select>
            </GridItem>
        </GridContainer>
        <GridContainer>
            <GridItem xs={12}>
                <Select
                    native
                    variant="outlined"
                    value={maxTeams}
                    style={{width: "50%", marginTop: "1rem", marginBottom: "0.5rem"}}
                    inputProps={{
                        name: "maxTeams",
                        id: "maxTeams"
                    }}
                    onChange={handleSimple}
                >
                    <option value="" disabled>Number Of Teams</option>
                    <option classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                            value={2}>2
                    </option>
                    <option classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                            value={4}>4
                    </option>
                    <option classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                            value={8}>8
                    </option>
                    <option classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                            value={16}>16
                    </option>
                    <option classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                            value={32}>32
                    </option>
                    <option classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                            value={64}>64
                    </option>
                </Select>
            </GridItem>
        </GridContainer>
        <GridContainer>
            <GridItem xs={12}>
                <TextField
                    id="outlined-basic"
                    label="Members Per Team"
                    name="teamSize"
                    autoFocus={false}
                    margin="normal"
                    type="number"
                    variant="outlined"
                    fullWidth={false}
                    required={true}
                    onChange={handleSimple}
                />
            </GridItem>
        </GridContainer>
        {bracketType === "BATTLE_ROYALE" ?
            <div>
                <GridContainer>
                    <GridItem xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Number of Rounds"
                            name="rounds"
                            type="number"
                            autoFocus={false}
                            margin="normal"
                            variant="outlined"
                            fullWidth={false}
                            required={true}
                            onChange={handleSimple}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Points to Win"
                            name="pointsToWin"
                            type="number"
                            autoFocus={false}
                            margin="normal"
                            variant="outlined"
                            fullWidth={false}
                            required={true}
                            onChange={handleSimple}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} xl={4} lg={4} style={{paddingTop: "2rem"}}>
                        <Paper style={{width: '100%', overflow: "auto"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Team Position</TableCell>
                                        <TableCell>Points Awarded Per Round</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cells}
                                </TableBody>
                            </Table>
                        </Paper>
                        <h6>This is only for players to understand how the points system works. Leave blank to
                            disregard.</h6>
                    </GridItem>
                </GridContainer>
            </div>
            : <div/>}
    </div>
}

export default withStyles(componentsStyle)(BracketDetails);
