import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import gql from "graphql-tag";
import TextField from "@material-ui/core/TextField";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import {apolloClient, isUserSignedIn} from "../../utils";

const style = {};

const CLAIM_ACCOUNT = gql`
    mutation claimAccount($token: String!, $newPassword: String!) {
        claimAccount(token: $token, newPassword: $newPassword)
    }`;

class ClaimAccount extends React.Component {
    state = {
        token: "",
        newPassword: "",
        success: false,
        error: false
    };

    componentDidMount = async () => {
        if (await isUserSignedIn()) {
            this.props.history.push("/");
        }
        this.setState({token: this.props.match.params.token});
    };

    submit = () => {
        apolloClient.mutate({
            mutation: CLAIM_ACCOUNT,
            variables: {token: this.state.token, newPassword: this.state.newPassword}
        }).then(response => {
            if (response.data.claimAccount === true) {
                this.setState({success: true});
            } else {
                this.setState({error: true});
            }
        });
    };

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render = () => {

        if (this.state.success) {
            return <div style={{minHeight: "50rem"}}>
                <GridContainer justify="center" style={{textAlign: "center", marginTop: "5rem", color: "green"}}>
                    <GridItem xs={12} md={7}><Typography variant="subtitle1">Password successfully reset, please login.</Typography></GridItem>
                </GridContainer>
            </div>
        }
        if (this.state.error) {
            return <div style={{minHeight: "50rem"}}>
                <GridContainer justify="center" style={{textAlign: "center", marginTop: "5rem", color: "red"}}>
                    <GridItem xs={12} md={7}><Typography variant="subtitle1">Please try to reset password again.</Typography></GridItem>
                </GridContainer>
            </div>
        }

        return <div style={{minHeight: "50rem"}}>
            <GridContainer justify="center" style={{textAlign: "center", marginTop: "5rem"}}>
                <GridItem xs={12} md={7}>
                    <Typography variant="h5" style={{paddingBottom: "2rem"}}>
                        Reset Password
                    </Typography>
                </GridItem>
                <GridItem xs={12} md={7}>
                    <TextField
                        id="outlined-basic"
                        label="Reset Token"
                        name="token"
                        autoFocus={false}
                        disabled={true}
                        margin="normal"
                        value={this.state.token}
                        variant="outlined"
                        fullWidth={false}
                        required={true}
                    />
                </GridItem>
                <GridItem xs={12} md={7}>
                    <TextField
                        id="outlined-basic"
                        label="New Password"
                        name="newPassword"
                        margin="normal"
                        variant="outlined"
                        type="password"
                        fullWidth={false}
                        required={true}
                        onChange={this.handleSimple}
                    />
                </GridItem>
                <GridItem xs={12} md={7}>
                    <Button
                        color="success"
                        size="lg"
                        onClick={this.submit}
                    >
                        Submit
                    </Button>
                </GridItem>
            </GridContainer>
        </div>
    }
}

export default withStyles(style)(ClaimAccount);
