import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableRow, Typography,
    withStyles
} from "@material-ui/core";
import Card from "components/Card/Card";
import Close from "@material-ui/icons/Close";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import {apolloClient} from "../../../utils";
import gql from "graphql-tag";
import Button from "components/CustomButtons/Button";

const GET_MY_OWNED_TEAMS = gql`{
    getMyOwnedTeams {
        id
        name
        members {
            id
            name
        }
    }
}`;

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

function ParticipateModal({...props}) {
    const {
        classes,
        closeModal,
        history,
        openState,
        teamSize,
        handleParticipation,
        buyIn,
        buyInFee,
        token,
        handleFunding
    } = props;

    const [teams, setTeams] = useState([]);
    useEffect(() => {
        apolloClient.query({query: GET_MY_OWNED_TEAMS, fetchPolicy: 'network-only'}).then(res => {
            const data = res.data.getMyOwnedTeams;

            const newTeams = [];
            data.forEach((team, i) => {
                newTeams.push(<TableRow cursor="pointer" key={i + 1}>
                    <TableCell component="th" scope="row">
                        {team.name}
                    </TableCell>
                    <TableCell>
                        {team.members.length === 1 ? team.members.length + " Player" : team.members.length + " Players"}
                    </TableCell>
                    <TableCell>
                        {team.members.length === teamSize ?
                            <Button color="success" onClick={() => {handleParticipation(team.id); closeModal("participateModal");}}>Enroll</Button> :
                            <Button color="warning"
                                    onClick={() => history.push(`/modifyTeam/${team.id}`)}>Modify</Button>}
                    </TableCell>
                </TableRow>);
            });
            setTeams(newTeams);
        }).catch(error => {
            console.log({error});
        });
    }, [openState, history, teamSize, closeModal, handleParticipation]);

    return (
        <Dialog
            classes={{
                root: classes.center,
                paper: classes.modal
            }}
            open={openState}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => closeModal("participateModal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <IconButton
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => closeModal("participateModal")}
                >
                    <Close className={classes.modalClose}/>
                </IconButton>
                <Typography variant="h4">Join Tournament</Typography>
            </DialogTitle>
            {buyIn ? <DialogContent id="modal-slide-description" className={classes.modalBody}>
                <Card plain={true} style={{textAlign: "center"}}>
                    <Typography variant="h6">Enroll To Continue...</Typography>
                </Card>
                <div style={{textAlign: "center"}}>
                    <Typography variant="subtitle2">Buy In Cost: ${(buyInFee * token.usdPrice).toFixed(3)} ({buyInFee} in {token.name})</Typography>
                    <br/>
                    <Button color="success" onClick={handleFunding}>
                        Enroll with {token.name}
                    </Button>
                    <Button color="success" disabled={true}>
                        Enroll with credit card
                    </Button>
                </div>
            </DialogContent> :
            <DialogContent id="modal-slide-description" className={classes.modalBody}>
                <Card plain={true} style={{textAlign: "center"}}>
                    <Typography variant="h6">Enroll To Continue</Typography>
                    <Typography variant="subtitle2">Required players per team: {teamSize}</Typography>
                </Card>
                <Card plain={true}>
                    <Table>
                        <TableBody>
                            {teams}
                        </TableBody>
                    </Table>
                </Card>
                <div style={{textAlign: "center"}}>
                    <Typography variant="subtitle2">Need more teammates?</Typography>
                    <Button color="github" onClick={() => history.push("/newTeam")}>
                        Create new team
                    </Button>
                </div>
            </DialogContent>}
        </Dialog>
    );
}

export default withStyles(modalStyle)(ParticipateModal);
