import * as React from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import ReactMarkdown from "react-markdown";

export default class MarkdownEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "write"
        };
    }

    handleTabChange = (tab) => {
        this.setState({tab})
    };

    render() {
        return (
            <div className="container">
                <ReactMde
                    minEditorHeight="20rem"
                    onChange={this.props.handleTextChange}
                    onTabChange={this.handleTabChange}
                    value={this.props.text}
                    generateMarkdownPreview={markdown =>
                        Promise.resolve(<ReactMarkdown
                            source={markdown}
                            escapeHtml={true}
                        />)
                    }
                    selectedTab={this.state.tab}
                />
            </div>
        );
    }
}
