import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import MarkdownEditor from "../../components/MarkdownEditor/MarkdownEditor";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

function BasicDetails({...props}) {
    const {handleSimple, handleTextBox, handleDate, description, game, games, unlisted, handleSwitch, classes} = props;

    return <div>
        <GridContainer>
            <GridItem xs={12}>
                <TextField
                    id="outlined-basic"
                    label="Tournament Name"
                    name="title"
                    autoFocus={true}
                    margin="normal"
                    variant="outlined"
                    fullWidth={false}
                    required={true}
                    onChange={handleSimple}
                />
            </GridItem>
        </GridContainer>
        <GridContainer justify="center">
            <GridItem xs={12}>
                <Tooltip
                    id="tooltip-right"
                    title="Note: Dates are displayed in user's local timezone."
                    placement="right-end"
                >
                    <div
                        className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal">
                        <Datetime
                            className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl"
                            onChange={handleDate}
                            inputProps={{
                                className: "MuiInputBase-input MuiOutlinedInput-input",
                                autoComplete: "off",
                                name: "deadline",
                                required: true,
                                placeholder: "Start Time *",
                                style: {
                                    border: "solid",
                                    borderColor: "lightgrey",
                                    borderWidth: "thin",
                                    borderRadius: "0.45rem",
                                    color: "black",
                                    fontWeight: "405"
                                }
                            }}
                        />
                    </div>
                </Tooltip>
            </GridItem>
        </GridContainer>
        <GridContainer>
            <GridItem xs={12}>
                <Select
                    native
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    style={{width: "50%", marginTop: "1rem", marginBottom: "0.5rem"}}
                    onChange={handleSimple}
                    value={game}
                    variant="outlined"
                    inputProps={{
                        name: "game",
                        id: "game"
                    }}
                >
                    <option value="" disabled>
                        Game
                    </option>
                    {games.map(game => {
                        return <option
                            key={game.id}
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                            }}
                            value={game.name}
                        >
                            {game.name}
                        </option>
                    })}
                </Select>
            </GridItem>
        </GridContainer>
        <GridContainer style={{paddingTop: "1rem"}}>
            <GridItem xs={12}>
                <Tooltip title="An Unlisted tournament does not show on the front page. Only users with the link can access it."><FormControl
                    component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={unlisted}
                                    onChange={handleSwitch('unlisted')}
                                    value="unlisted"
                                />
                            }
                            label={<Typography color="textPrimary">Unlisted Tournament</Typography>}
                        />
                    </FormGroup>
                </FormControl>
                </Tooltip>
            </GridItem>
        </GridContainer>
        <GridContainer style={{marginTop: "2rem", textAlign: "left"}}>
            <GridItem xs={12}>
                <MarkdownEditor text={description}
                                handleTextChange={handleTextBox}/>
            </GridItem>
        </GridContainer>
    </div>
}

export default withStyles(componentsStyle)(BasicDetails);
