import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button";
import {Card, CardContent, CardHeader, Typography, withStyles} from "@material-ui/core";
import Pills from "./Pills.jsx";
import abi from "abis/tournamentAbi";
import humanStandardTokenAbi from "abis/humanStandardToken";
import {base, contract_address} from "constants.js";
import {apolloClient, prepUserForContract, sleep, initWeb3} from "utils";
import {
    ADD_PARTICIPANT, GENERATE_BRACKET,
    GET_TOURNAMENT,
    PICK_WINNER,
    REMOVE_PARTICIPANT,
    ROUND_UPDATE,
    START_TOURNAMENT
} from "./graphs";
import "../Components/App.css"; // TODO: Make sure it's safe to delete this.
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import PrizesModal from "./modals/PrizesModal";
import ContestantsModal from "./modals/ContestantsModal";
import ContributeModal from "./modals/ContributeModal";
import ParticipateModal from "./modals/ParticipateModal";
import SelectWinnerModal from "./modals/SelectWinnerModal";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import BattleRoyale from "./BattleRoyale";
import WhitelistContributorModal from "./modals/WhitelistContributorModal";
import {isUserSignedIn} from "../../utils";
import PaymentsOverviewModal from "./modals/PaymentsOverviewModal";
import Parallax from "../../components/Parallax/Parallax";
import CardFooter from "../../components/Card/CardFooter";
import Snackbar from "../../components/Snackbar/Snackbar";
import CircularProgress from "../../components/CircularProgress";

const $ = (window.jQuery = require("jquery"));
require("../../../node_modules/jquery-bracket/dist/jquery.bracket.min.js");

class Tournament extends React.Component {
    state = {
        id: null,
        tournament: null,
        maxPlayers: 0,
        participants: [],
        matches: [],
        matchesL: [],
        user: {},
        owner: {},
        web3: null,
        assistInstance: null,
        contract: null,
        decoratedContract: null,
        tokenName: "ETH",
        tokenVersion: 0,
        contribution: 0,
        tokenUsdPrice: 0,
        prize: 0,
        deadline: 0,
        title: "",
        contributorPublicAddress: "",
        prizesModal: false,
        contestantsModal: false,
        contributeModal: false,
        participateModal: false,
        selectWinnerModal: false,
        whitelistContributorModal: false,
        match: {},
        isUserSignedIn: false,
        coverImage: null,
        isOwner: false,
        errorMessage: "",
        successMessage: "",
        success: false,
        error: false
    };

    handleModalClickOpen = modal => {
        var x = [];
        x[modal] = true;
        this.setState(x);
    };

    handleModalClose = modal => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    };

    componentDidMount = async () => {
        this.setState({id: this.props.match.params.id});
        this.getTournament(this.props.match.params.id);
        this.setState({isUserSignedIn: await isUserSignedIn()});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.matches.length > 0 && this.brackets !== undefined) {
            const teams = [];
            this.state.matches.forEach((match, i) => {
                if (i < this.state.tournament.bracketSize / 2) {
                    teams.push([match.team1.name, match.team2.name]);
                }
            });

            if (this.state.tournament.bracketType === "SINGLE_ELIMINATION") {
                let matchCount = this.state.matches.length;
                let count = 0;
                let rounds = [];
                let round = [];
                this.state.matches.forEach((match) => {
                    if (count > matchCount / 2) {
                        rounds.push(round);
                        matchCount = matchCount - count;
                        count = 0;
                        round = [];
                    }
                    if (match.winner) {
                        if (match.winner.id === match.team1.id) {
                            round.push([1, 0, match]);
                        } else {
                            round.push([0, 1, match]);
                        }
                    } else {
                        round.push([null, null, match]);
                    }
                    count++;
                });

                // Add in final match
                let final = [];
                const finalMatch = this.state.matches[this.state.matches.length - 1];
                if (finalMatch.winner) {
                    if (finalMatch.winner.id === finalMatch.team1.id) {
                        final.push([1, 0, finalMatch]);
                    } else {
                        final.push([0, 1, finalMatch]);
                    }
                } else {
                    final.push([null, null, finalMatch]);
                }

                // Add in 3rd place match
                const thirdPlaceMatch = this.state.tournament.matchesL[0];
                if (thirdPlaceMatch.winner) {
                    if (thirdPlaceMatch.winner.id === thirdPlaceMatch.team1.id) {
                        final.push([1, 0, thirdPlaceMatch]);
                    } else {
                        final.push([0, 1, thirdPlaceMatch]);
                    }
                } else {
                    final.push([null, null, thirdPlaceMatch]);
                }

                const results = [...rounds, final];
                const singleElimination = {
                    teams: teams,
                    results: results
                };

                $(
                    $(this.brackets).bracket({
                        teamWidth: 100,
                        centerConnectors: true,
                        onMatchClick: this.state.isOwner && this.state.tournament.tournamentStatus === "LIVE" ? this.handleMatchClick : null,
                        init: singleElimination
                    })
                );
            } else if (this.state.tournament.bracketType === "DOUBLE_ELIMINATION") {

                const winnersBracket = [];
                const losersBracket = [];
                const finalBracket = [];

                let counter = 0;
                let roundSwitch = this.state.tournament.bracketSize / 2;
                let round = [];
                this.state.tournament.matches.forEach(match => {
                    counter = this.assignRound(match, round, counter);
                    if (counter === roundSwitch) {
                        roundSwitch /= 2;
                        counter = 0;
                        winnersBracket.push(round);
                        round = [];
                    }
                });
                counter = 0;
                roundSwitch = this.state.tournament.bracketSize / 4;
                round = [];
                let mixAndMatchRound = false;
                this.state.tournament.matchesL.forEach(match => {
                    if (counter === roundSwitch) {
                        if (mixAndMatchRound) {
                            roundSwitch /= 2;
                            mixAndMatchRound = false;
                        } else {
                            mixAndMatchRound = true;
                        }
                        counter = 0;
                        losersBracket.push(round);
                        round = [];
                    }
                    counter = this.assignRound(match, round, counter);
                });

                // Final match is stored as last match in lower bracket
                const match = this.state.tournament.matchesL[this.state.tournament.matchesL.length - 1];
                if (match.winner) {
                    if (match.winner.id === match.team1.id) {
                        finalBracket.push([1, 0, match]);
                    } else {
                        finalBracket.push([0, 1, match]);
                    }
                } else {
                    finalBracket.push([null, null, match]);
                }

                const results = [winnersBracket, losersBracket, [finalBracket]];

                const doubleElimination = {
                    teams: teams,
                    results: results
                };

                $(
                    $(this.brackets).bracket({
                        teamWidth: 100,
                        centerConnectors: true,
                        skipConsolationRound: true,
                        skipSecondaryFinal: true,
                        onMatchClick: this.state.isOwner && this.state.tournament.tournamentStatus === "LIVE" ? this.handleMatchClick : (match) => console.log({match}),
                        init: doubleElimination
                    })
                );
            }
        }
    }

    initializeWeb3 = async () => {
        let bncAssistConfig = {
            messages: {
                txPending: data => {
                    if (data.contract.methodName === "approve") {
                        return "Approving funds to be contributed.";
                    } else {
                        return "Transaction Pending";
                    }
                },
                txConfirmed: data => {
                    if (data.contract.methodName === "approve") {
                        this.setState(
                            {
                                decoratedContract: this.state.assistInstance.Contract(
                                    this.state.web3.eth.contract(abi).at(contract_address)
                                )
                            },
                            () => {
                                this.state.decoratedContract.contribute(
                                    this.state.tournament.tournamentId,
                                    this.state.contribution,
                                    {
                                        from: this.state.user.publicAddress
                                    },
                                    (err) => {
                                        if (!err) {
                                            window.location.reload();
                                        }
                                    }
                                );
                            }
                        );
                        return `${this.state.web3.fromWei(this.state.contribution, "ether")} ${
                            this.state.tokenName
                        } approved.`;
                    } else if (data.contract.methodName === "contribute") {
                        sleep(10000).then(() => {
                            window.location.reload();
                            return "Contribution successful.";
                        });
                    } else if (data.contract.methodName === "payoutWinners") {
                        sleep(10000).then(() => {
                            this.getTournament(this.props.match.params.id);
                            return "Winners successfully paid.";
                        });
                    } else {
                        return "Transaction Confirmed";
                    }
                }
            },
            style: {
                darkMode: true
            }
        };
        const web3Init = await initWeb3(bncAssistConfig);
        await this.setState({web3: web3Init.web3, assistInstance: web3Init.assistInstance});
    };

    getTournament = id => {
        apolloClient
            .query({
                query: GET_TOURNAMENT(id)
            })
            .then(response => {
                if (response.loading) return "Loading...";
                if (response.error) return `Error!`;
                const data = response.data;
                this.setState({
                    ...this.state.tournament,
                    tournament: data.tournament
                });
                this.setState({tokenName: data.tournament.token.symbol});
                this.setState({tokenVersion: data.tournament.token.tokenVersion});
                this.setState({coverImage: data.tournament.coverImage});
                this.setState({owner: data.tournament.owner});
                this.setState({maxPlayers: data.tournament.maxPlayers});
                this.setState({title: data.tournament.name});
                this.setState({
                    ...this.state.participants,
                    participants: data.tournament.participants
                });
                this.setState({...this.state.matches, matches: data.tournament.matches});
                this.setState({...this.state.matchesL, matchesL: data.tournament.matchesL});
                this.setState({tokenUsdPrice: data.tournament.token.usdPrice});
                this.setState({prize: data.tournament.prize});
                this.setState({deadline: data.tournament.deadline});
                this.setState({tournamentType: data.tournament.tournamentType});
                this.setState({isOwner: this.state.tournament.owner.id === localStorage.getItem("userId")});
                return null;
            });
    };

    handleMatchClick = match => {
        if (localStorage.getItem("userId") === this.state.tournament.owner.id) {
            this.setState({match: match});
            this.handleModalClickOpen("selectWinnerModal");
        }
    };

    handleFiles = e => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        axios.post(`${base}/api/tournament/${this.state.id}/coverImage`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            this.setState({success: true});
            this.setState({successMessage: "Cover photo uploaded successfully."});
            sleep(3000).then(() => {
                this.setState({coverImage: response.data.fileUrl});
            });
        }).catch(() => {
            this.setState({error: true});
            this.setState({errorMessage: "Error uploading cover photo."});
        })
    };

    handleFilesThumbnail = e => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        axios.post(`${base}/api/tournament/${this.state.id}/thumbnail`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            this.setState({successMessage: "Thumbnail photo uploaded successfully."});
            this.setState({success: true});
            sleep(3000).then(() => {
                this.setState({thumbnail: response.data.fileUrl});
            });
        }).catch(() => {
            this.setState({errorMessage: "Error uploading thumbnail photo."});
            this.setState({error: true});
        });
    };

    snackBar = () => {
        return <div><Snackbar
            message={this.state.successMessage}
            close
            color="success"
            open={this.state.success}
            place="tl"
            closeNotification={() => this.setState({success: false})}
        /><Snackbar
            message={this.state.errorMessage}
            close
            color="danger"
            open={this.state.error}
            place="tl"
            closeNotification={() => this.setState({error: false})}
        />
        </div>
    };

    handleParticipation = teamId => {
        apolloClient.mutate({
            variables: {tournamentId: this.state.tournament.id, teamId},
            mutation: ADD_PARTICIPANT
        }).then(response => {
            if (response.loading) return "Loading...";
            if (response.error) return console.error("Error when enrolling team.");

            const tournament = response.data.addParticipant;
            this.setState({
                ...this.state.tournament,
                tournament: tournament
            });
            this.setState({
                ...this.state.participants,
                participants: tournament.participants
            });
            this.setState({
                ...this.state.matches,
                matches: tournament.matches
            });
            return null;
        });
    };

    removeParticipant = teamId => {
        apolloClient.mutate({
            mutation: REMOVE_PARTICIPANT,
            variables: {tournamentId: this.state.tournament.id, teamId}
        }).then(res => {
            if (res.loading) return "Loading...";
            if (res.error) return console.error("Error when removing team.");

            const tournament = res.data.removeParticipant;
            this.setState({
                ...this.state.tournament,
                tournament: tournament
            });
            this.setState({
                ...this.state.participants,
                participants: tournament.participants
            });
            this.setState({
                ...this.state.matches,
                matches: tournament.matches
            });
            return null;
        });
    };

    handleStartTournament = () => {
        apolloClient.mutate({
            variables: {tournamentId: this.state.tournament.id},
            mutation: START_TOURNAMENT
        }).then(response => {
            this.setState({tournament: response.data.startTournament});
            this.setState({
                ...this.state.matches,
                matches: response.data.startTournament.matches
            });
        });
    };

    generateBracket = () => {
        apolloClient.mutate({
            variables: {tournamentId: this.state.tournament.id},
            mutation: GENERATE_BRACKET
        }).then(response => {
            this.setState({tournament: response.data.generateBracket});
            this.setState({
                ...this.state.matches,
                matches: response.data.generateBracket.matches
            });
        });
    };

    handlePointUpdate = event => {
        const round = event.target.name.split(",")[0];
        const userId = event.target.name.split(",")[1];
        const tournament = this.state.tournament;
        tournament.rounds[round][userId] = parseInt(event.target.value);
        this.setState({tournament: tournament});
        console.log(tournament);
    };

    endRound = roundNumber => {
        apolloClient.mutate({
            variables: {
                tournamentId: this.state.tournament.id,
                roundNumber,
                standings: this.state.tournament.rounds[roundNumber]
            },
            mutation: ROUND_UPDATE
        }).then(response => {
            this.setState({tournament: response.data.roundUpdate});
        });
    };

    handleWinner = (matchId, num) => {
        apolloClient
            .mutate({
                variables: {pos: Number(num), matchId: matchId, tournamentId: this.state.tournament.id},
                mutation: PICK_WINNER
            })
            .then(response => {
                this.setState({tournament: response.data.matchWinner});
                this.setState({...this.state.matches, matches: response.data.matchWinner.matches});
                this.setState({...this.state.matchesL, matchesL: response.data.matchWinner.matchesL});
            });
    };

    handlePayment = async () => {
        await this.initializeWeb3();
        prepUserForContract(this.state.assistInstance, this.props.history).then(
            responseData => {
                this.setState(
                    {
                        decoratedContract: this.state.assistInstance.Contract(
                            this.state.web3.eth.contract(abi).at(contract_address)
                        )
                    },
                    () => {
                        let winners = this.state.tournament.winners.map(winner =>
                            winner.user.walletType === "NO_WALLET" ? this.state.owner.publicAddress : winner.user.publicAddress
                        );
                        const unassigned = this.state.tournament.bracketType === "BATTLE_ROYALE" ?
                            (this.state.tournament.maxTeams - this.state.tournament.participants.length) * this.state.tournament.teamSize
                            : (this.state.tournament.maxTeams - 4) * this.state.tournament.teamSize;
                        winners = winners.concat(Array(unassigned).fill(this.state.tournament.owner.publicAddress));
                        console.table(winners);

                        this.state.decoratedContract.payoutWinners(
                            this.state.tournament.tournamentId,
                            winners,
                            {from: this.state.tournament.owner.publicAddress},
                            (err) => {
                                if (!err) {
                                    console.log("Payments Successful");
                                }
                            }
                        );
                    }
                );
            }
        );
    };

    handleFunding = async () => {

        if (this.state.tournamentType === "BUY_IN") {
            this.setState({contribution: this.state.tournament.buyInFee});
        }

        await this.initializeWeb3();
        prepUserForContract(this.state.assistInstance, this.props.history).then(
            responseData => {
                this.setState({...this.state.user, user: responseData});
                if (this.state.tokenVersion === 20) {

                    // Fix up to BigNumber
                    let decimals = this.state.web3.toBigNumber(18); // TODO: Get this from token object
                    let amount = this.state.web3.toBigNumber(this.state.contribution);
                    let value = amount.times(this.state.web3.toBigNumber(10).pow(decimals));
                    this.setState({contribution: value});

                    this.setState(
                        {
                            decoratedContract: this.state.assistInstance.Contract(
                                this.state.web3.eth
                                    .contract(humanStandardTokenAbi)
                                    .at(this.state.tournament.token.address)
                            )
                        },
                        () => {
                            this.state.decoratedContract.approve(
                                contract_address,
                                this.state.contribution,
                                {from: this.state.user.publicAddress}
                            );
                        }
                    );
                } else if (this.state.tokenVersion === 0) {
                    this.setState(
                        {
                            decoratedContract: this.state.assistInstance.Contract(
                                this.state.web3.eth.contract(abi).at(contract_address)
                            )
                        },
                        () => {
                            this.state.decoratedContract.contribute.sendTransaction(
                                this.state.tournament.tournamentId,
                                this.state.web3.toWei(this.state.contribution, "ether"),
                                {
                                    from: this.state.user.publicAddress,
                                    value: this.state.web3.toWei(this.state.contribution, "ether"),
                                    gas: 200000
                                }
                            );
                        }
                    );
                } else {
                    window.alert("Working on it!");
                }
            });
    };

    handleContributorWhitelist = async () => {
        await this.initializeWeb3();
        prepUserForContract(this.state.assistInstance, this.props.history)
            .then(responseData => {
                this.setState(
                    {
                        decoratedContract: this.state.assistInstance.Contract(
                            this.state.web3.eth.contract(abi).at(contract_address)
                        )
                    }, () => {
                        console.log({addr: this.state.contributorPublicAddress});
                        this.state.decoratedContract.whitelistContributor(this.state.tournament.tournamentId, this.state.contributorPublicAddress, {from: this.state.user.publicAddress}, (err) => {
                            if (!err) console.log("Whitelisting successful")
                        });
                    });
            });
    };

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {classes} = this.props;

        if (this.state.tournament === null) {
            return <div style={{minHeight: "70rem", marginTop: "3rem", textAlign: "center"}}><CircularProgress/></div>;
        }

        return (
            <div style={{marginTop: "0.75rem", textAlign: "center"}}>
                <Parallax image={this.state.coverImage}>
                    <div
                        style={{
                            position: "absolute",
                            top: "20px",
                            left: "15px",
                            color: "white",
                            backgroundColor: "transparent",
                            fontSize: "x-large",
                            zIndex: "2"
                        }}
                    >
                        {this.state.owner.id === localStorage.getItem("userId") ? (
                            <div>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    style={{display: "none"}}
                                    id="raised-button-file"
                                    multiple
                                    onChange={this.handleFiles}
                                    type="file"
                                />
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    style={{display: "none"}}
                                    id="raised-button-file-tn"
                                    multiple
                                    onChange={this.handleFilesThumbnail}
                                    type="file"
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        variant="raised"
                                        component="span"
                                        className={classes.button}
                                    >
                                        Change Cover
                                    </Button>
                                </label>
                                <label htmlFor="raised-button-file-tn">
                                    <Button
                                        variant="raised"
                                        component="span"
                                        className={classes.button}
                                    >
                                        Change Thumbnail
                                    </Button>
                                </label>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </Parallax>
                <GridContainer justify="center">
                    <GridItem xs={12} md={8} lg={8} xl={8} style={{paddingTop: "3rem", paddingBottom: "2rem"}}>
                        <Card square variant="outlined">
                            <GridContainer justify="center">
                                <GridItem xs={12} style={{paddingTop: "3rem", paddingBottom: "2rem"}}>
                                    <Typography variant="h2"
                                                style={{
                                                    fontWeight: "bold"
                                                }}>{this.state.title}</Typography>
                                </GridItem>
                                <GridItem xs={12} md={8} lg={8} xl={8}>
                                    <Pills
                                        prize={this.state.prize}
                                        tokenName={this.state.tokenName}
                                        participants={this.state.participants.length}
                                        tourType={this.state.tournament.tournamentType}
                                        bracketType={this.state.tournament.bracketType}
                                        teamSize={this.state.tournament.teamSize}
                                        deadline={this.state.deadline}
                                        handleModalClickOpen={this.handleModalClickOpen}
                                    />
                                </GridItem>
                                <GridItem xs={12} md={8} lg={8} xl={8}
                                          style={{paddingTop: "2rem"}}>
                                    <Button
                                        color="success"
                                        onClick={() => this.state.isUserSignedIn ? this.handleModalClickOpen("participateModal") : this.props.history.push("/signUp")}
                                        disabled={
                                            this.state.participants.filter(x => x.owner.id === localStorage.getItem("userId")).length > 0
                                            || this.state.tournament.tournamentStatus !== "NEW"
                                        }
                                    >
                                        Join Tournament
                                    </Button>
                                    {this.state.tournamentType === "PRIZE_POOL" ?
                                        <Button
                                            color="success"
                                            onClick={() => this.state.isUserSignedIn ? this.handleModalClickOpen("contributeModal") : this.props.history.push("/signUp")}
                                            disabled={this.state.tournament.tournamentStatus === "FINISHED" || this.state.tournament.tournamentStatus === "COMPLETE"}
                                        >
                                            Contribute To Prize Pool
                                        </Button>
                                        : ""}
                                    {this.state.tournament.owner && this.state.tournament.owner.id !== localStorage.getItem("userId") ?
                                        <div/>
                                        :
                                        <span>
                                <Button
                                    color="success"
                                    onClick={() => this.handleModalClickOpen("whitelistContributorModal")}
                                >
                                    White List Contributor
                                </Button>
                                <Button
                                    color="success"
                                    onClick={() => this.handleModalClickOpen("paymentsOverviewModal")}
                                >
                                    Payments Overview
                                </Button>

                                            {this.state.tournament.tournamentStatus === "NEW" ?
                                                <Button
                                                    color="success"
                                                    onClick={this.handleStartTournament}
                                                >
                                                    Start Tournament!
                                                </Button>
                                                : <div/>
                                            }
                            </span>
                                    }
                                </GridItem>
                                <GridItem xs={12} lg={10} xl={8}
                                          style={{
                                              textAlign: "left",
                                              paddingTop: "3rem",
                                              fontFamily: "Roboto, \"Helvetica\", \"Arial\", sans-serif"
                                          }}>
                                    <Card variant="outlined" square>
                                        <CardContent>
                                            {this.state.tournament ?
                                                <ReactMarkdown
                                                    source={this.state.tournament.description}
                                                    escapeHtml={true}
                                                />
                                                : ""}
                                        </CardContent>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} lg={10} xl={8}>
                                    {(this.state.tournament.tournamentStatus !== "NEW" || this.state.isOwner) && (this.state.tournament.bracketType === "DOUBLE_ELIMINATION"
                                        || this.state.tournament.bracketType === "SINGLE_ELIMINATION") ? (
                                        <Card variant="outlined" style={{overflow: "auto"}} square>
                                            <CardHeader title="Tournament Bracket"/>
                                            {this.state.tournament.tournamentStatus === "NEW" ?
                                                <Button onClick={this.generateBracket}
                                                        style={{backgroundColor: "#2a2a7d", borderRadius: "0.5rem"}}>Generate
                                                    New Bracket</Button> : ""}
                                            <div ref={node => this.brackets = node} className="App"/>
                                            <CardFooter/>
                                        </Card>
                                    ) : null}
                                </GridItem>
                                <GridItem xs={12} lg={10} xl={8}>
                                    {this.state.tournament && this.state.tournament.bracketType === "BATTLE_ROYALE" ?
                                        <BattleRoyale handlePointUpdate={this.handlePointUpdate}
                                                      maxPlayers={this.state.tournament.maxPlayers}
                                                      tournamentStatus={this.state.tournament.tournamentStatus}
                                                      organizer={this.state.tournament.owner.id === localStorage.getItem("userId")}
                                                      rounds={this.state.tournament.rounds}
                                                      numberOfRounds={this.state.tournament.numberOfRounds}
                                                      participants={this.state.tournament.participants}
                                                      endRound={this.endRound}
                                                      pointsToWin={this.state.tournament.pointsToWin}
                                                      pointsDistribution={this.state.tournament.pointsDistribution}
                                                      live={this.state.tournament.tournamentStatus === "LIVE"}
                                        /> : null}
                                </GridItem>
                                {this.state.isOwner && this.state.tournament.tournamentStatus === "FINISHED" ?
                                    <GridItem xs={12} md={8} lg={8} xl={8}>
                                        <Button color="success" onClick={() => {
                                            this.setState({paymentsOverviewModal: true});
                                            this.handlePayment();
                                        }}>
                                            Issue Payments
                                        </Button>
                                    </GridItem>
                                    : ""}
                            </GridContainer>
                            <CardFooter/>
                        </Card>
                    </GridItem>
                </GridContainer>
                <PrizesModal
                    openState={this.state.prizesModal}
                    closeModal={this.handleModalClose}
                    tournament={this.state.tournament}
                />
                <ContestantsModal
                    openState={this.state.contestantsModal}
                    closeModal={this.handleModalClose}
                    participants={this.state.participants}
                    maxTeams={this.state.tournament.maxTeams}
                    isOwner={this.state.owner.id === localStorage.getItem("userId")}
                    isBuyIn={this.state.tournament.tournamentType === "BUY_IN"}
                    removeParticipant={this.removeParticipant}
                    history={this.props.history}
                />
                <ContributeModal
                    openState={this.state.contributeModal}
                    closeModal={this.handleModalClose}
                    tokenName={this.state.tokenName}
                    handleSimple={this.handleSimple}
                    handleFunding={this.handleFunding}
                />
                <ParticipateModal
                    openState={this.state.participateModal}
                    history={this.props.history}
                    closeModal={this.handleModalClose}
                    teamSize={this.state.tournament.teamSize}
                    handleParticipation={this.handleParticipation}
                    buyIn={this.state.tournament.tournamentType === "BUY_IN"}
                    buyInFee={this.state.tournament.buyInFee}
                    token={this.state.tournament.token}
                    handleFunding={this.handleFunding}
                />
                <SelectWinnerModal
                    openState={this.state.selectWinnerModal}
                    closeModal={this.handleModalClose}
                    match={this.state.match}
                    handleWinner={this.handleWinner}
                />
                <WhitelistContributorModal
                    openState={this.state.whitelistContributorModal}
                    closeModal={this.handleModalClose}
                    handleSimple={this.handleSimple}
                    handleContributorWhitelist={this.handleContributorWhitelist}
                />
                <PaymentsOverviewModal
                    openState={this.state.paymentsOverviewModal}
                    closeModal={this.handleModalClose}
                    winners={this.state.tournament.winners}
                    prizeDistribution={this.state.tournament.prizeDistribution}
                    prize={this.state.tournament.prize}
                    token={this.state.tournament.token}
                    teamSize={this.state.tournament.teamSize}
                    history={this.props.history}
                />
                {this.snackBar()}
            </div>
        );
    }

    assignRound(match, round, counter) {
        if (match.winner) {
            if (match.winner.id === match.team1.id) {
                round.push([1, 0, match]);
            } else {
                round.push([0, 1, match]);
            }
        } else {
            round.push([null, null, match]);
        }
        counter += 1;
        return counter;
    }
}

export default withStyles(componentsStyle)(Tournament);
