import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import axios from "axios/index";
import {base} from "constants.js";
import {apolloClient} from "utils";
import gql from "graphql-tag";
import CardBody from "../../components/Card/CardBody";
import {sleep} from "utils";
import Snackbar from "../../components/Snackbar/Snackbar";
import {isUserSignedIn} from "../../utils";
import {TextField} from "@material-ui/core";
import moment from "moment";
import CardFooter from "../../components/Card/CardFooter";

const EDIT_USER_METADATA = gql`
    mutation metadata($metadata: UpdateUserInput!) {
        updateMyMetadata(metadata: $metadata) {
            id
            name
            email
            username
            walletType
        }
    }`;

const UPDATE_MY_PASSWORD = gql`
    mutation password($oldPassword: String, $newPassword: String) {
        updateMyPassword(oldPassword: $oldPassword, newPassword: $newPassword)
    }`;

const GET_MY_PROFILE = gql` {
    myProfile {
        id
        name
        email
        username
        publicAddress
        walletType
        socialLinks {
            instagram
            twitter
            twitch
            facebook
            mixer
            youtube
        }
        dob
        avatar
    }
}`;

class EditUserForm extends React.Component {
    state = {
        user: null,
        name: "",
        email: "",
        username: "",
        publicAddress: "",
        walletType: "NO_WALLET",
        toastMessage: "",
        error: false,
        success: false
    };

    componentDidMount = async () => {
        if (!await isUserSignedIn()) {
            this.props.history.push("/");
        }
        apolloClient
            .query({
                query: GET_MY_PROFILE,
                fetchPolicy: "network-only"
            }).then(response => {
            const responseData = response.data.myProfile;
            this.setState({...this.state.user, user: responseData});
            this.setState({name: responseData.name});
            this.setState({email: responseData.email});
            this.setState({username: responseData.username});
            this.setState({walletType: responseData.walletType});
            this.setState({publicAddress: responseData.publicAddress});
            this.setState({avatar: responseData.avatar});
            this.setState({twitter: responseData.socialLinks.twitter});
            this.setState({twitch: responseData.socialLinks.twitch});
            this.setState({instagram: responseData.socialLinks.instagram});
            this.setState({mixer: responseData.socialLinks.mixer});
            this.setState({facebook: responseData.socialLinks.facebook});
            this.setState({youtube: responseData.socialLinks.youtube});
        }).catch(error => {
            this.setState({error: true});
            console.error({error});
        });
    };

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmit = event => {
        event.preventDefault();
        apolloClient
            .mutate({
                variables: {
                    metadata: {
                        name: this.state.name,
                        email: this.state.email,
                        username: this.state.username,
                        socials: {
                            instagram: this.state.instagram,
                            twitter: this.state.twitter,
                            twitch: this.state.twitch,
                            facebook: this.state.facebook,
                            mixer: this.state.mixer,
                            youtube: this.state.youtube
                        }
                    }
                },
                mutation: EDIT_USER_METADATA
            }).then(() => {
            this.setState({toastMessage: "Successfully updated."});
            this.setState({success: true});
        }).catch(error => {
            this.setState({toastMessage: "Error when updating user information."});
            this.setState({error: true});
            console.error({error});
        });
    };

    handleUpdatePassword = event => {
        event.preventDefault();
        apolloClient
            .mutate({
                variables: {oldPassword: this.state.oldPassword, newPassword: this.state.newPassword},
                mutation: UPDATE_MY_PASSWORD
            }).then(() => {
            this.setState({toastMessage: "Successfully updated password."});
            this.setState({success: true});
        }).catch(error => {
            this.setState({toastMessage: "Error when updating password."});
            this.setState({error: true});
            console.error({error});
        });
    };

    handleAvatar = e => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        axios
            .post(`${base}/api/user/myAvatar`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(response => {
                sleep(3000).then(() => {
                    this.setState({avatar: response.data.fileUrl});
                    localStorage.setItem("userAvatar", response.data.fileUrl);
                });
            });
    };

    snackBar = () => {
        return <div><Snackbar
            message={this.state.toastMessage}
            close
            color="success"
            open={this.state.success}
            place="tl"
            closeNotification={() => this.setState({success: false})}
        /><Snackbar
            message={this.state.toastMessage}
            close
            color="danger"
            open={this.state.error}
            place="tl"
            closeNotification={() => this.setState({error: false})}
        />
        </div>
    };

    render() {
        const {classes} = this.props;

        if (!this.state.user) {
            return <div style={{minHeight: "70rem"}}/>;
        }

        return (
            <div style={{minHeight: "70rem", textAlign: "center"}}>
                <GridContainer justify="center">
                    <GridItem xs={12} md={6} lg={6} xl={4}>
                        <Card>
                            <CardHeader>
                                <h2 className={classes.cardTitle}>My Information</h2>
                            </CardHeader>
                            <div>
                                <form onSubmit={this.handleSubmit}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Date of Birth"
                                                name="dob"
                                                autoFocus={false}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth={false}
                                                value={moment(this.state.user.dob).format("MM-DD-YYYY")}
                                                disabled={true}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Name"
                                                name="name"
                                                autoFocus={true}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth={false}
                                                onChange={this.handleSimple}
                                                value={this.state.name}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer><GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Username"
                                            name="username"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.username}
                                        />
                                    </GridItem></GridContainer>
                                    <GridContainer><GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Email"
                                            name="email"
                                            type="email"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.email}
                                        />
                                    </GridItem></GridContainer>
                                    <GridContainer justify="center">
                                        <GridItem xs={2}>
                                            <Button
                                                type="primary"
                                                size="lg"
                                                color="success"
                                                htmltype="submit"
                                                block
                                                style={{marginTop: "5rem"}}
                                            >
                                                Submit
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </div>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6} lg={6} xl={4}>
                        <Card>
                            <CardHeader>
                                <h2 className={classes.cardTitle}>My Social Links</h2>
                            </CardHeader>
                            <div>
                                <form onSubmit={this.handleSubmit}>
                                    <GridContainer><GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Instagram"
                                            name="instagram"
                                            type="text"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="https://instagram.com/example"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.instagram}
                                        />
                                    </GridItem></GridContainer>
                                    <GridContainer><GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Facebook"
                                            name="facebook"
                                            type="text"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="https://facebook.com/example"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.facebook}
                                        />
                                    </GridItem></GridContainer>
                                    <GridContainer><GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Twitter"
                                            name="twitter"
                                            type="text"
                                            margin="normal"
                                            placeholder="https://twitter.com/example"
                                            variant="outlined"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.twitter}
                                        />
                                    </GridItem></GridContainer>
                                    <GridContainer><GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Twitch"
                                            name="twitch"
                                            type="text"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="https://twitch.tv/example"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.twitch}
                                        />
                                    </GridItem></GridContainer>
                                    <GridContainer><GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Mixer"
                                            name="mixer"
                                            type="text"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="https://mixer.com/example"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.mixer}
                                        />
                                    </GridItem></GridContainer>
                                    <GridContainer><GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Youtube"
                                            name="youtube"
                                            type="text"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="https://youtube.com/example"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.youtube}
                                        />
                                    </GridItem></GridContainer>
                                    <GridContainer justify="center">
                                        <GridItem xs={2}>
                                            <Button
                                                type="primary"
                                                size="lg"
                                                color="success"
                                                htmltype="submit"
                                                block
                                                style={{marginTop: "5rem"}}
                                            >
                                                Submit
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </div>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6} lg={6} xl={4}>
                        <Card>
                            <CardHeader>
                                <h2 className={classes.cardTitle}>My Wallet</h2>
                            </CardHeader>
                            {this.state.walletType === "NO_WALLET" ? <GridContainer>
                                <GridItem xs={12}>
                                    <h6>You do not have a wallet connected.</h6>
                                </GridItem>
                            </GridContainer> : <>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Public Address"
                                            name="publicAddress"
                                            autoFocus={false}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.publicAddress}
                                            disabled={true}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Wallet Provider"
                                            name="walletProvider"
                                            autoFocus={false}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                            value={this.state.walletType.toLowerCase().replace("_", " ")}
                                            disabled={true}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <h6>Note: This account is tied to the provided public address, therefore
                                            it
                                            cannot be changed. Please create a new account if you want to use a
                                            different public address.</h6>
                                    </GridItem>
                                </GridContainer>
                            </>}
                            <CardFooter/>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6} lg={6} xl={4}>
                        <Card>
                            <CardHeader>
                                <h2 className={classes.cardTitle}>Update Password</h2>
                            </CardHeader>
                            <form onSubmit={this.handleUpdatePassword}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Old Password"
                                            type="password"
                                            name="oldPassword"
                                            autoFocus={false}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="New Password"
                                            type="password"
                                            name="newPassword"
                                            autoFocus={false}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth={false}
                                            onChange={this.handleSimple}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justify="center">
                                    <GridItem xs={2}>
                                        <Button
                                            type="primary"
                                            color="success"
                                            htmltype="submit"
                                            size="lg"
                                            style={{marginTop: "5rem"}}
                                            block
                                        >
                                            Submit
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6} lg={6} xl={4}>
                        <Card>
                            <CardHeader>
                                <h2>My Avatar</h2>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <img style={{
                                        marginRight: "0.5rem",
                                        verticalAlign: "bottom",
                                        height: "10rem",
                                        width: "9.5rem"
                                    }}
                                         src={this.state.avatar} alt={this.state.name}/>
                                    <img style={{
                                        marginRight: "0.5rem",
                                        verticalAlign: "bottom",
                                        height: "5rem",
                                        width: "4.5rem"
                                    }} src={this.state.avatar}
                                         alt={this.state.name}/>
                                    <img style={{
                                        marginRight: "0.5rem",
                                        verticalAlign: "bottom",
                                        height: "2rem",
                                        width: "1.8rem"
                                    }} src={this.state.avatar}
                                         alt={this.state.name}/>
                                </div>
                                <br/>
                                <GridContainer justify="center">
                                    <input
                                        accept="image/*"
                                        className={classes.input}
                                        style={{display: "none"}}
                                        id="raised-button-file"
                                        multiple
                                        onChange={this.handleAvatar}
                                        type="file"
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Button
                                            variant="raised"
                                            component="span"
                                            color="success"
                                            size="lg"
                                            style={{marginTop: "3rem"}}
                                            className={classes.button}
                                        >
                                            Upload New Avatar
                                        </Button>
                                    </label>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {this.snackBar()}
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(componentsStyle)(EditUserForm);
