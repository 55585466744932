import React from "react";
import {
    withStyles,
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import tooltipsStyle from "assets/jss/material-kit-react/tooltipsStyle.jsx";
import Button from "../../../components/CustomButtons/Button";
import {exportToCsv} from "utils/exportToCSV";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

function action(isBuyIn, isOwner, id, removeParticipant) {
    if (!isOwner || isBuyIn) {return null;}
    return <TableCell>
        <Button onClick={() => removeParticipant(id)} color="warning" size="sm">
            Remove
        </Button>
    </TableCell>
}

const downloadParticipantsCSV = participants => {
    const rows = [['username', 'name', 'email', 'team name', 'team id', 'team twitch', 'team owner', 'dob', 'instagram', 'facebook', 'twitter', 'twitch', 'mixer', 'youtube', 'walletType', 'publicAddress', 'id']];
    participants.forEach(team => {
        team.members.forEach(user => {
            rows.push([
                user.username,
                user.name,
                user.email,
                team.name,
                team.id,
                team.twitch,
                team.owner.username,
                user.dob.toString(),
                user.socialLinks.instagram,
                user.socialLinks.facebook,
                user.socialLinks.twitter,
                user.socialLinks.twitch,
                user.socialLinks.mixer,
                user.socialLinks.youtube,
                user.walletType,
                user.publicAddress,
                user.id
            ])
        });
    });
    exportToCsv("participants.csv", rows);
};

function ContestantsModal({...props}) {
    const {classes, openState, closeModal, isOwner, isBuyIn, removeParticipant, participants, maxTeams, history} = props;

    if (!openState) {
        return <div/>;
    }

    return (
        <Dialog
            classes={{
                root: classes.center,
                paper: classes.modal
            }}
            open={openState}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => closeModal("contestantsModal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <IconButton
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => closeModal("contestantsModal")}
                >
                    <Close className={classes.modalClose}/>
                </IconButton>
                Participants
            </DialogTitle>
            <DialogContent id="modal-slide-description" className={classes.modalBody}>
                <h3>
                    {participants.length} / {maxTeams} Registered
                </h3>
                {participants.length > 0 ? (
                    <Table>
                        <TableBody>
                            {participants.map((team, i) => (
                                <TableRow cursor="pointer" key={i + 1}>
                                    <TableCell>
                                        {i+1}
                                    </TableCell>
                                    <TableCell onClick={() => history.push("/team/" + team.id)} style={{textDecoration: "underline", cursor: "pointer"}}>
                                        {team.name}
                                    </TableCell>
                                    {action(isBuyIn, isOwner, team.id, removeParticipant)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    ""
                )}
                {isOwner ? <div style={{textAlign: "center", marginTop: "2rem"}}><Button onClick={() => downloadParticipantsCSV(participants)} color="success">
                    Download list as CSV file
                </Button></div> : ""}
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(modalStyle, tooltipsStyle)(ContestantsModal);
