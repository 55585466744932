import React from "react";
import {
    withStyles,
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import tooltipsStyle from "assets/jss/material-kit-react/tooltipsStyle.jsx";
import Typography from "@material-ui/core/Typography";
import TableHead from "@material-ui/core/TableHead";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

function PaymentsOverviewModal({...props}) {
    const {classes, openState, closeModal, winners, teamSize, prize, prizeDistribution, history, token} = props;

    if (!openState) {
        return <div/>;
    }
    let i = 0;
    let team = 0;

    const rows = [];
    winners.forEach(winner => {
        console.log(prizeDistribution[team]);
        rows.push(<TableRow onClick={() => history.push("/user/" + winner.user.id)} style={{cursor: "pointer"}}
                            key={winner.user.id}>
            <TableCell>
                {winner.user.username}
            </TableCell>
            <TableCell>
                {winner.team.name}
            </TableCell>
            <TableCell>
                {Number(((prizeDistribution[team] * prize) / 100) / teamSize).toFixed(4)}
            </TableCell>
            <TableCell>
                ${Number((((prizeDistribution[team] * prize) / 100) * token.usdPrice) / teamSize).toFixed(4)}
            </TableCell>
            <TableCell>
                {winner.user.walletType === "NO_WALLET" ? "USD" : "CRYPTO"}
            </TableCell>
        </TableRow>);
        i++;
        if (i === teamSize) {
            team++;
            i = 0;
        }
    });

    return (
        <Dialog
            classes={{
                root: classes.center,
                paper: classes.modal
            }}
            open={openState}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => closeModal("paymentsOverviewModal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <IconButton
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => closeModal("paymentsOverviewModal")}
                >
                    <Close className={classes.modalClose}/>
                </IconButton>
                Payments Overview
            </DialogTitle>
            <DialogContent id="modal-slide-description" className={classes.modalBody}>
                <Typography variant="subtitle1">
                    * Please be sure to process payments manually for players marked with USD payments. Their earnings
                    will be deposited into your wallet once the tournament is finalized.
                </Typography>
                <Table>
                    <TableHead>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Team
                        </TableCell>
                        <TableCell>
                            Earnings In {token.name}
                        </TableCell>
                        <TableCell>
                            Earnings In USD
                        </TableCell>
                        <TableCell>
                            Payment
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(modalStyle, tooltipsStyle)(PaymentsOverviewModal);
