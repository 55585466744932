import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs";
import {Paper, Table, TableHead, TableBody, TableRow, TableCell, withStyles, Input, Button} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const orderByPoints = (user1, user2) => {
    if (user1[1] < user2[1]) return +1;
    else if (user1[1] > user2[1]) return -1;
    else return 0;
};

function BattleRoyale({...props}) {
    const {handlePointUpdate, pointsDistribution, tournamentStatus, numberOfRounds, maxPlayers, organizer, rounds, participants, endRound, live} = props;

    let roundsObject = [];
    if (tournamentStatus !== "NEW") {
        for (let i = 0; i < rounds.length; i++) {
            roundsObject.push({
                tabName: `Round ${i + 1}`,
                tabContent: assembleRoundTable(maxPlayers, organizer, rounds[i], i, handlePointUpdate, participants, endRound, live)
            })
        }

    }

    /** Overview tab **/
    let users = [];
    for (let i = 0; i < rounds.length; i++) {
        Object.entries(rounds[i]).forEach(([userId, points]) => {
            if (users[userId]) {
                users[userId] = users[userId] + points;
            } else {
                users[userId] = points;
            }
        });
    }

    let cells = [];
    let i = 1;
    pointsDistribution.forEach(points => {
        cells.push(
            <TableRow cursor="pointer" key={i}>
                <TableCell component="th" scope="row">{i}</TableCell>
                <TableCell component="th" scope="row">{points}</TableCell>
            </TableRow>
        );
        i++;
    });
    const totals = [];
    let currentStandingsTab = [];
    if (tournamentStatus !== "NEW") {
        let i = 0;
        Object.entries(users).sort(orderByPoints).forEach(([userId, points]) => {
            totals.push(
                <TableRow cursor="pointer" key={i}>
                    <TableCell component="th"
                               scope="row">{participants.find(player => player.id === userId).name}</TableCell>
                    <TableCell component="th" scope="row">{points}</TableCell>
                </TableRow>
            );
            i++;
        });
        currentStandingsTab.push({
            tabName: "Current Standings",
            tabContent: (
                <GridContainer justify="center">
                    <GridItem xs={12} xl={4} lg={4}>
                        <Paper style={{width: '100%', overflow: "auto", height: 400}} variant="outlined" square>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Team</TableCell>
                                        <TableCell>Points</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {totals}
                                </TableBody>
                            </Table>
                        </Paper>
                    </GridItem>
                </GridContainer>
            )
        });
    }
    /** Overview tab **/

    return <GridItem xs={12} style={{marginTop: "6rem", marginBottom: "3rem"}}>
        <CustomTabs
            plainTabs={true}
            headerColor="dark"
            tabs={[
                ...currentStandingsTab,
                {
                    tabName: "Points Distribution",
                    tabContent: (
                        <GridContainer justify="center">
                            <GridItem xs={12} xl={4} lg={4}>
                                <Typography variant="subtitle2">Rounds: {numberOfRounds}</Typography>
                                <h6>The team with the most points after the last round wins.</h6>
                                <Paper style={{width: '100%', overflow: "auto", height: 400}} variant="outlined" square>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Team Position</TableCell>
                                                <TableCell>Points</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cells}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    )
                },
                ...roundsObject
            ]}
        />
    </GridItem>
}

const assembleRoundTable = (maxPlayers, organizer, round, roundNumber, handlePointUpdate, participants, endRound, live) => {
    let cells = [];
    let i = 0;
    Object.entries(round).sort(orderByPoints).forEach(([userId, points]) => {
        cells.push(
            <TableRow cursor="pointer" key={i}>
                <TableCell component="th"
                           scope="row">{participants.find(player => player.id === userId).name}</TableCell>
                {organizer && live ? <TableCell component="th" scope="row"><Input
                    inputProps={{
                        name: roundNumber + "," + userId,
                        value: points,
                        type: "number",
                        onChange: handlePointUpdate,
                        placeholder: 0,
                        required: true
                    }}
                /></TableCell> : <TableCell component="th" scope="row">{points}</TableCell>}
            </TableRow>
        );
        i++;
    });

    return <GridContainer justify="center">
        <GridItem xs={12} xl={4} lg={4}>
            <Paper style={{width: '100%', overflow: "auto", height: 400}} variant="outlined" square>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Player Name</TableCell>
                            <TableCell>Points Earned</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cells}
                    </TableBody>
                </Table>
            </Paper>
        </GridItem>
        {organizer && live ?
            <GridItem xs={12} xl={12} lg={12}><Button style={{marginTop: "2rem"}} variant="outlined"
                                                      onClick={() => endRound(roundNumber)}>Submit</Button></GridItem> : ""}
    </GridContainer>
};

export default withStyles(componentsStyle)(BattleRoyale);
