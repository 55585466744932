import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/Input/index";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

function PrizeDetails({...props}) {
    const {handleSimple, handleSwitch, restrictContributors, prizeToken, prizeDescription, tournamentType, tokens, classes} = props;

    let tokenList = [];
    tokens.forEach(token => {
        tokenList.push(
            <option
                value={token.address}
            >
                {token.symbol}
            </option>
        );
    });
    tokenList.push(
        <MenuItem
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
            }}
            value="other"
        >
            Other
        </MenuItem>
    );

    return <div>
        <GridContainer>
            <GridItem xs={12}>
                <Select
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    native
                    style={{width: "50%", marginTop: "1rem", marginBottom: "0.5rem"}}
                    variant="outlined"
                    value={prizeToken}
                    onChange={handleSimple}
                    inputProps={{
                        name: "prizeToken",
                        id: "prizeToken"
                    }}
                >
                    <option value="" disabled>
                        Prize Token
                    </option>
                    {tokenList}
                </Select>
            </GridItem>
        </GridContainer>
        {prizeToken === "other" ? (
            <GridContainer>
                <GridItem xs={4}>
                    <h5>Prize Description</h5>
                </GridItem>
                <GridItem xs={8}>
                    <Input
                        fullWidth={true}
                        multiline={true}
                        rows={5}
                        inputProps={{
                            name: "prizeDescription",
                            type: "text",
                            value: prizeDescription,
                            onChange: handleSimple,
                            required: true
                        }}
                    />
                </GridItem>
            </GridContainer>
        ) : <div>
            <GridContainer>
                <GridItem xs={12}>
                    <Select
                        style={{width: "50%", marginTop: "1rem", marginBottom: "0.5rem"}}
                        native
                        variant="outlined"
                        MenuProps={{
                            className: classes.selectMenu
                        }}
                        classes={{
                            select: classes.select
                        }}
                        onChange={handleSimple}
                        value={tournamentType}
                        inputProps={{
                            name: "tournamentType",
                            id: "tournamentType"
                        }}
                    >
                        <option value="" disabled>
                            Tournament Type
                        </option>
                        <option
                            value="PRIZE_POOL"
                        >
                            Prize Pool
                        </option>
                        <option
                            value="BUY_IN"
                        >
                            Buy In
                        </option>
                    </Select>
                </GridItem>
            </GridContainer>
            {tournamentType === "BUY_IN" ?
                <GridContainer>
                    <GridItem xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Buy In Fee"
                            name="buyInFee"
                            autoFocus={false}
                            margin="normal"
                            type="number"
                            variant="outlined"
                            fullWidth={false}
                            required={true}
                            onChange={handleSimple}
                            inputProps={{step: "0.001", min: "0"}}
                        />
                    </GridItem>
                </GridContainer>
                : ""
            }
            {tournamentType !== "PRIZE_POOL" ? "" : <GridContainer style={{paddingTop: "1rem"}}>
                <GridItem xs={12}>
                    <Tooltip title="Requires each contributor to be whitelisted before they can chip into the pool."><FormControl
                        component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={restrictContributors}
                                        onChange={handleSwitch('restrictContributors')}
                                        value="restrictContributors"
                                    />
                                }
                                label={<Typography color="textPrimary">Restrict Contributors</Typography>}
                            />
                        </FormGroup>
                    </FormControl>
                    </Tooltip>
                </GridItem>
            </GridContainer>}
        </div>}
    </div>
}

export default withStyles(componentsStyle)(PrizeDetails);
