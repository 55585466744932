import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import gql from "graphql-tag";
import {apolloClient} from "../../utils";
import {Typography} from "@material-ui/core";
import moment from "moment";
import CardBody from "../../components/Card/CardBody";
import CircularProgress from "../../components/CircularProgress";

const GET_TOURNAMENTS = gql`
    {
        findTournamentsByString(count: 18, offset: 0, fieldName: "platform", fieldData: "nacl") {
            game {
                id
                name
            }
            unlisted
            name
            description
            id
            teamSize
            maxTeams
            participants {
                id
            }
            tournamentStatus
            coverImage
            thumbnail
            deadline
            createdAt
        }
    }
`;

class LandingPage extends React.Component {
    state = {
        currentTournaments: [<CircularProgress/>],
        oldTournaments: []
    };

    componentDidMount = () => {
        const classes = this.props.classes;
        apolloClient.query({
            query: GET_TOURNAMENTS
        }).then((response) => {
            const currentTournaments = [];
            const oldTournaments = [];
            response.data.findTournamentsByString.forEach(tournament => {
                if (tournament.tournamentStatus === "NEW" || tournament.tournamentStatus === "LIVE") {
                    if (tournament.unlisted) return;
                    currentTournaments.push(
                        <GridItem xs={12} sm={8} md={6} lg={4} xl={3} wrap="nowrap" className={classes.grid}>
                            <Card
                                className={classes.card}
                                onClick={() => this.handleRedirect(`/tournament/${tournament.id}`)}
                            >
                                <img
                                    src={tournament.thumbnail}
                                    alt={"cover"}
                                    className={classes.cover}
                                />
                                <CardBody className={classes.cardBody}>
                                    <Typography variant="subtitle2" noWrap>{tournament.game.name}</Typography>
                                    <Typography variant="subtitle1" style={{fontWeight: "bold"}} noWrap>{tournament.name}</Typography>
                                    <Typography variant="body2" style={{paddingTop: "1rem"}} noWrap>
                                        Date:{" "}
                                        {moment(
                                            tournament.deadline,
                                            "YYYY-MM-DDTHH:mm:ss:SSZ"
                                        ).format("LLL")}
                                    </Typography>
                                    <Typography variant="body2" noWrap>Team Size: {tournament.teamSize}</Typography>
                                    <Typography variant="body2"
                                                noWrap>{tournament.participants.length} / {tournament.maxTeams} Registered</Typography>
                                </CardBody>
                            </Card>
                        </GridItem>
                    );
                } else {
                    oldTournaments.push(
                        <GridItem xs={12} sm={8} md={6} lg={4} xl={3} wrap="nowrap" className={classes.grid}>
                            <Card
                                className={classes.card}
                                onClick={() => this.handleRedirect(`/tournament/${tournament.id}`)}
                            >
                                <img
                                    src={tournament.thumbnail}
                                    alt={"cover"}
                                    className={classes.cover}
                                />
                                <CardBody className={classes.cardBody}>
                                    <Typography variant="subtitle2" noWrap>{tournament.game.name}</Typography>
                                    <Typography variant="subtitle1" style={{fontWeight: "bold"}} noWrap>{tournament.name}</Typography>
                                    <Typography variant="body2" style={{paddingTop: "1rem"}} noWrap>
                                        Date:{" "}
                                        {moment(
                                            tournament.deadline,
                                            "YYYY-MM-DDTHH:mm:ss:SSZ"
                                        ).format("LLL")}
                                    </Typography>
                                    <Typography variant="body2" noWrap>Team Size: {tournament.teamSize}</Typography>
                                    <Typography variant="body2"
                                                noWrap>{tournament.participants.length} / {tournament.maxTeams} Registered</Typography>
                                </CardBody>
                            </Card>
                        </GridItem>
                    );
                }
            });

            if (currentTournaments < 1) {
                this.setState({currentTournaments: [<h3>No Tournaments Yet</h3>]});
            } else {
                this.setState({currentTournaments});
            }
            this.setState({oldTournaments});
        });
    };

    handleRedirect = path => {
        this.props.history.push(path);
    };

    render() {
        const {classes} = this.props;
        return (
            <div style={{overflow: "hidden", minHeight: "70rem"}}>
                <GridContainer className={classes.mainContainer} justify="center">
                    <GridItem xs={12} md={8}>
                        <Card plain={true} style={{marginTop: "2rem"}}>
                            <h1 style={{fontWeight: "bold", color: "#ff7932", textAlign: "center"}}>Current
                                Tournaments</h1>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={8} lg={8} xl={8}>
                        <GridContainer justify="center" spacing={6}>
                            {this.state.currentTournaments}
                        </GridContainer>
                    </GridItem>
                    {this.state.oldTournaments.length > 0 ? <GridItem xs={12} md={8}>
                        <Card plain={true} style={{marginTop: "2rem"}}>
                            <h1 style={{fontWeight: "bold", color: "#ff7932", textAlign: "center"}}>Past
                                Tournaments</h1>
                        </Card>
                    </GridItem> : ""}
                    {this.state.oldTournaments.length > 0 ? <GridItem xs={12} md={8} lg={8} xl={8}>
                        <GridContainer justify="center" spacing={6}>
                            {this.state.oldTournaments}
                        </GridContainer>
                    </GridItem> : ""}
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(componentsStyle)(LandingPage);
