import {withStyles, Typography, Zoom} from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import {apolloClient, isUserSignedIn} from "utils";
import gql from "graphql-tag";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import ConnectWalletForm from "./ConnectWalletForm";
import {platformName} from "../../constants";
import AddSocialLinksForm from "./AddSocialLinksForm";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import {asyncLocalStorage} from "../../utils";

const SIGNUP_USER = gql`
    mutation signUpUser($metadata: NewUserInput!) {
        signUpUser(metadata: $metadata) {
            accessToken
            tokenType
            username
            publicAddress
            userAvatar
            userId
            walletType
            roles {
                id
                name
            }
        }
    }`;

class SignUpForm extends React.Component {
    state = {
        user: null,
        web3: null,
        name: "",
        email: "",
        publicAddress: "",
        walletType: "NO_WALLET",
        privacyPolicyModal: false,
        ageVerified: false,
        policyVerified: false,
        submitted: false,
        success: false,
        failure: false,
        web3Modal: null,
        ready: null
    };

    componentDidMount = async () => {
        if (await isUserSignedIn() === true) {
            this.props.history.push("/");
        }
    };

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    setWalletType = (walletType, callback) => {
        this.setState({walletType}, callback);
    };

    setPublicAddress = (publicAddress, callback) => {
        this.setState({publicAddress}, callback);
    };

    handleSubmitMain = (event) => {
        event.preventDefault();
        this.setState({initSocials: true});
    };

    handleSubmitSocials = (event) => {
        event.preventDefault();
        this.setState({initWallets: true});
        this.setState({initSocials: false});
    };

    handleChange = event => {
        this.setState({...this.state, [event.target.name]: event.target.checked});
    };

    popPrivacyPolicy = () => {
        this.setState({privacyPolicyModal: true});
    };

    closePrivacyPolicy = () => {
        this.setState({privacyPolicyModal: false});
    };

    createUser = () => {
        let publicAddress = this.state.publicAddress;
        let walletType;
        if (this.state.walletType === "NO_WALLET") {
            publicAddress = '0x0';
            walletType = "NO_WALLET";
        } else if (this.state.walletType === "injected") {
            walletType = "WEB3_BROWSER";
        } else {
            walletType = this.state.walletType.toUpperCase();
        }

        apolloClient.mutate({
            variables: {
                metadata: {
                    publicAddress,
                    username: this.state.username,
                    name: this.state.name,
                    email: this.state.email,
                    walletType,
                    password: this.state.password,
                    platform: platformName,
                    socials: {
                        twitter: this.state.twitter,
                        youtube: this.state.youtube,
                        twitch: this.state.twitch,
                        mixer: this.state.mixer,
                        facebook: this.state.facebook,
                        instagram: this.state.instagram
                    }
                }
            },
            mutation: SIGNUP_USER
        }).then(res => {
            setSignedOnUserInStorage(res.data.signUpUser).then(() => {
                window.location.reload();
            });
        }).catch(() => {
            this.setState({failure: true});
            this.setState({initSocials: false});
            this.setState({initWallets: false});
        })
    };

    render = () => {
        if (this.state.initSocials) {
            return <AddSocialLinksForm handleSubmitSocials={this.handleSubmitSocials} handleSimple={this.handleSimple}/>
        }
        if (this.state.initWallets) {
            return <ConnectWalletForm createUser={this.createUser}
                                      setWalletType={this.setWalletType}
                                      setPublicAddress={this.setPublicAddress}/>
        }

        if (this.state.success) {
            return <GridContainer justify="center" style={{minHeight: "70rem"}}>
                <GridItem xs={6}>
                    <Card plain={true}>
                        <CardBody>
                            <h3 style={{color: "green"}}>User successfully created, please sign in!</h3>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        }

        if (this.state.failure) {
            return <GridContainer justify="center" style={{minHeight: "70rem"}}>
                <GridItem xs={6}>
                    <Card plain={true}>
                        <CardBody>
                            <h3 style={{color: "red"}}>Error creating user!</h3>
                            <Button color="danger" onClick={() => {
                                this.setState({failure: false})
                            }}>Try Again</Button>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        }

        return <div><Zoom in={true}>
            <GridContainer justify="center" style={{textAlign: "center", minHeight: "70rem", marginTop: "3rem"}}>
                <GridItem xs={12} md={6} lg={6} xl={6}>
                    <Card plain={true}>
                        <CardHeader>
                            <Typography variant="h4">Sign Up</Typography>
                        </CardHeader>
                        <div>
                            <form onSubmit={this.handleSubmitMain}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Username"
                                            name="username"
                                            autoFocus={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth={false}
                                            required={true}
                                            onChange={this.handleSimple}
                                            inputProps={{
                                                pattern: ".{3,}",
                                                title: "Username must be three or more characters"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Name"
                                            name="name"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth={false}
                                            required={true}
                                            onChange={this.handleSimple}
                                            inputProps={{
                                                pattern: ".{3,}",
                                                title: "Name must be three or more characters"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Email"
                                            type="email"
                                            name="email"
                                            margin="normal"
                                            variant="outlined"
                                            value={this.state.email}
                                            fullWidth={false}
                                            required={true}
                                            onChange={this.handleSimple}
                                            inputProps={{
                                                pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Password"
                                            name="password"
                                            margin="normal"
                                            variant="outlined"
                                            type="password"
                                            fullWidth={false}
                                            required={true}
                                            onChange={this.handleSimple}
                                            inputProps={{
                                                pattern: "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}",
                                                title: "Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justify="center">
                                    <GridItem xs={12} style={{marginTop: "2rem"}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.ageVerified}
                                                    onChange={this.handleChange}
                                                    name="ageVerified"
                                                    color="primary"
                                                    required={true}
                                                />}
                                            label="I am at least 13 years of age or older"
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justify="center">
                                    <GridItem xs={12} style={{marginTop: "2rem"}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.policyVerified}
                                                    onChange={this.handleChange}
                                                    name="policyVerified"
                                                    color="primary"
                                                    required={true}
                                                />}
                                            label="I agree to the privacy policy"
                                        />
                                        <Typography variant="subtitle2 overline"
                                                    style={{cursor: "pointer", textDecoration: "underline"}}
                                                    onClick={this.popPrivacyPolicy}>View</Typography>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justify="center">
                                    <GridItem xs={6} md={4} lg={2}>
                                        <Button
                                            type="primary"
                                            color="success"
                                            htmltype="submit"
                                            size="lg"
                                            disabled={!this.state.ageVerified || !this.state.policyVerified}
                                            block
                                            style={{marginTop: "3rem"}}
                                        >
                                            <Typography variant="button">Submit</Typography>
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </div>
                    </Card>
                </GridItem>
            </GridContainer>
        </Zoom>
            <PrivacyPolicyModal closeModal={this.closePrivacyPolicy} openState={this.state.privacyPolicyModal}/>
        </div>
    }
}

const setSignedOnUserInStorage = data => {
    return new Promise(async resolve => {
        await asyncLocalStorage.setItem("username", data.username);
        await asyncLocalStorage.setItem(
            "publicAddress",
            data.publicAddress
        );
        await asyncLocalStorage.setItem("userId", data.userId);
        await asyncLocalStorage.setItem("jwtToken", data.accessToken);
        await asyncLocalStorage.setItem("userAvatar", data.userAvatar);
        await asyncLocalStorage.setItem("walletType", data.walletType);
        if (data.roles.filter(x => x.name === "ROLE_ORGANIZER").length > 0) {
            await asyncLocalStorage.setItem("organizer", "true");
        }
        resolve();
    });
};

export default withStyles(componentsStyle)(SignUpForm);
