import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import gql from "graphql-tag";
import TextField from "@material-ui/core/TextField";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import {apolloClient, isUserSignedIn} from "../../utils";

const style = {};

const FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String!) {
        forgotPassword(email: $email)
    }`;

class ForgotPassword extends React.Component {
    state = {
        email: "",
        success: false
    };

    componentDidMount = async () => {
        if (await isUserSignedIn()) {
            this.props.history.push("/");
        }
    };

    submit = () => {
        apolloClient.mutate({
            mutation: FORGOT_PASSWORD,
            variables: {email: this.state.email}
        }).then(() => {
            this.setState({success: true});
        });
    };

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render = () => {

        if (this.state.success) {
            return <div style={{minHeight: "50rem"}}>
                <GridContainer justify="center" style={{textAlign: "center", marginTop: "5rem", color: "green"}}>
                    <GridItem xs={12} md={7}><Typography variant="subtitle1">Successfully sent if user exists in our system. Please check your email.</Typography></GridItem>
                </GridContainer>
            </div>
        }

        return <div style={{minHeight: "50rem"}}>
            <GridContainer justify="center" style={{textAlign: "center", marginTop: "5rem"}}>
                <GridItem xs={12} md={7}>
                    <Typography variant="h5" style={{paddingBottom: "2rem"}}>
                        Reset Password
                    </Typography>
                </GridItem>
                <GridItem xs={12} md={7}>
                    <TextField
                        id="outlined-basic"
                        label="Email"
                        name="email"
                        autoFocus={true}
                        margin="normal"
                        variant="outlined"
                        fullWidth={false}
                        required={true}
                        onChange={this.handleSimple}
                    />
                </GridItem>
                <GridItem xs={12} md={7}>
                    <Button
                        color="warning"
                        size="lg"
                        onClick={this.submit}
                    >
                        Send Reset Email
                    </Button>
                </GridItem>
            </GridContainer>
        </div>
    }
}

export default withStyles(style)(ForgotPassword);
