import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch} from "react-router-dom";
import axios from "axios";
import "assets/scss/material-kit-react.scss?v=1.4.0";
import Header from "components/Header/Header.jsx";
// pages for this product
import LandingPage from "views/LandingPage/LandingPage.jsx";
import Tournament from "views/Tournament/Tournament.jsx";
import Organize from "views/Organize/Organize.jsx";
import EditUserForm from "views/EditUserForm/EditUserForm.jsx";
import Browse from "views/Browse/Browse.jsx";
import BrowseTournaments from "views/Browse/BrowseTournaments.jsx";
import {apolloClient} from "utils/index.js";
import {ApolloProvider} from "react-apollo";
import SignUpForm from "./views/SignUpForm/SignUpForm";
import MyTeams from "./views/Team/MyTeams";
import NewTeam from "./views/Team/NewTeam";
import ModifyTeam from "./views/Team/ModifyTeam";
import Team from "./views/Team/Team";
import Footer from "components/Footer/Footer";
import {WalletFAB} from "components/WalletFAB/WalletFAB";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import ClaimAccount from "./views/ForgotPassword/ClaimAccount";
import UserProfile from "./views/UserProfile/UserProfile";
import UserGuide from "./views/UserGuide/UserGuide";
import {isUserSignedIn} from "utils/index";
import {signOut} from "./utils";

const hist = createBrowserHistory();

// Configure axios
axios.defaults.headers.common = {
    "Authorization": "Bearer " + localStorage.getItem("jwtToken")
};

const clearUserTokenIfNotSignedOn = async () => {
    if (!await isUserSignedIn()) {
        await signOut();
    }
};
clearUserTokenIfNotSignedOn();

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
        <Header
            brand={<img src={require("assets/img/nacl.png")} alt={"NACL"} style={{height: "50px"}}/>}
            fixed
            color="dark"
            history={hist}
        />
        <Router history={hist}>
            <Switch>
                <Route path="/tournament/:id" component={Tournament} history={hist}/>
                <Route path="/organize" component={Organize} history={hist}/>
                <Route path="/editUser" component={EditUserForm} history={hist}/>
                <Route path="/signUp" component={SignUpForm} history={hist}/>
                <Route path="/forgotPassword" component={ForgotPassword} history={hist}/>
                <Route path="/claimAccount/:token" component={ClaimAccount} history={hist}/>
                <Route path="/browse" component={Browse} history={hist}/>
                <Route
                    path="/browseTournaments/:gameId"
                    component={BrowseTournaments}
                    history={hist}
                />
                <Route path="/explore" component={LandingPage} history={hist}/>
                <Route path="/userGuide" component={UserGuide} history={hist}/>
                <Route path="/myTeams" component={MyTeams} history={hist}/>
                <Route path="/newTeam" component={NewTeam} history={hist}/>
                <Route path="/modifyTeam/:id" component={ModifyTeam} history={hist}/>
                <Route path="/team/:id" component={Team} history={hist}/>
                <Route path="/user/:id" component={UserProfile} history={hist}/>
                <Route path="/" component={LandingPage} history={hist}/>
            </Switch>
        </Router>
        <Footer/>
        <WalletFAB/>
    </ApolloProvider>,
    document.getElementById("root")
);
