import React, {Component} from 'react';
import {Typography, Zoom} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import TextField from "@material-ui/core/TextField";
import CardHeader from "../../components/Card/CardHeader";

class AddSocialLinksForm extends Component {
    state = {
        assistInstance: null
    };

    render = () => {
        return <Zoom in={true}><GridContainer justify="center"
                                              style={{textAlign: "center", minHeight: "70rem", marginTop: "3rem"}}>
            <GridItem xs={12} md={6} lg={6} xl={6}>
                <Card plain={true}>
                    <CardHeader>
                        <Typography variant="h5">Add Your Social Profiles</Typography>
                        <Typography variant="subtitle1">* You can do this later</Typography>
                    </CardHeader>
                    <div>
                        <form onSubmit={this.props.handleSubmitSocials}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <TextField
                                        label="Facebook"
                                        name="facebook"
                                        autoFocus={true}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="https://facebook.com/example"
                                        fullWidth={false}
                                        required={false}
                                        onChange={this.props.handleSimple}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <TextField
                                        label="Youtube"
                                        name="youtube"
                                        autoFocus={false}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="https://youtube.com/example"
                                        fullWidth={false}
                                        required={false}
                                        onChange={this.props.handleSimple}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <TextField
                                        label="Mixer"
                                        name="mixer"
                                        autoFocus={false}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="https://mixer.com/example"
                                        fullWidth={false}
                                        required={false}
                                        onChange={this.props.handleSimple}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <TextField
                                        label="Instagram"
                                        name="instagram"
                                        autoFocus={false}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="https://instagram.com/example"
                                        fullWidth={false}
                                        required={false}
                                        onChange={this.props.handleSimple}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <TextField
                                        label="Twitter"
                                        name="twitter"
                                        autoFocus={false}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="https://twitter.com/example"
                                        fullWidth={false}
                                        required={false}
                                        onChange={this.props.handleSimple}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <TextField
                                        label="Twitch"
                                        name="twitch"
                                        autoFocus={false}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="https://twitch.tv/example"
                                        fullWidth={false}
                                        required={false}
                                        onChange={this.props.handleSimple}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                                <GridItem xs={6} md={4} lg={2}>
                                    <Button
                                        type="primary"
                                        color="success"
                                        htmltype="submit"
                                        size="lg"
                                        block
                                        style={{marginTop: "5rem"}}
                                    >
                                        <Typography variant="button">Submit</Typography>
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </div>
                </Card>
            </GridItem>
        </GridContainer></Zoom>;
    }
}

export default AddSocialLinksForm;
